import { render, staticRenderFns } from "./footer-main.vue?vue&type=template&id=1bd87123&scoped=true&"
import script from "./footer-main.vue?vue&type=script&lang=js&"
export * from "./footer-main.vue?vue&type=script&lang=js&"
import style0 from "./footer-main.vue?vue&type=style&index=0&id=1bd87123&prod&lang=scss&scoped=true&EmailSubsribe=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd87123",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterLogo: require('/home/satinder/Desktop/work/projects/rest-o-pain/main_project/rest-o-pain-main/components/logo/footer-logo.vue').default})
