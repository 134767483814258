import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21909e96 = () => interopDefault(import('../pages/conditions/index.vue' /* webpackChunkName: "pages/conditions/index" */))
const _d6adf8ee = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _5d6b64f4 = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _6df4d232 = () => interopDefault(import('../pages/thanks/index.vue' /* webpackChunkName: "pages/thanks/index" */))
const _05a4c719 = () => interopDefault(import('../pages/treatments/index.vue' /* webpackChunkName: "pages/treatments/index" */))
const _9619d9be = () => interopDefault(import('../pages/jobs/jobApplication.vue' /* webpackChunkName: "pages/jobs/jobApplication" */))
const _0c90a7a5 = () => interopDefault(import('../pages/treatments/acupuncture-therapy-in-edmonton.vue' /* webpackChunkName: "pages/treatments/acupuncture-therapy-in-edmonton" */))
const _10353754 = () => interopDefault(import('../pages/treatments/cupping-therapy-in-edmonton.vue' /* webpackChunkName: "pages/treatments/cupping-therapy-in-edmonton" */))
const _18555ad8 = () => interopDefault(import('../pages/treatments/dry-needling-therapy-in-edmonton.vue' /* webpackChunkName: "pages/treatments/dry-needling-therapy-in-edmonton" */))
const _d78ce3b2 = () => interopDefault(import('../pages/treatments/electrotherapeutic-therapy.vue' /* webpackChunkName: "pages/treatments/electrotherapeutic-therapy" */))
const _300588e5 = () => interopDefault(import('../pages/treatments/interferential-therapy.vue' /* webpackChunkName: "pages/treatments/interferential-therapy" */))
const _107f33b5 = () => interopDefault(import('../pages/treatments/joint-manipulative-therapy.vue' /* webpackChunkName: "pages/treatments/joint-manipulative-therapy" */))
const _4316fca8 = () => interopDefault(import('../pages/treatments/joint-manual-mobilization-therapy.vue' /* webpackChunkName: "pages/treatments/joint-manual-mobilization-therapy" */))
const _119db338 = () => interopDefault(import('../pages/treatments/kinesiology-taping-therapy.vue' /* webpackChunkName: "pages/treatments/kinesiology-taping-therapy" */))
const _7326402e = () => interopDefault(import('../pages/treatments/laser-therapy.vue' /* webpackChunkName: "pages/treatments/laser-therapy" */))
const _f6970c8c = () => interopDefault(import('../pages/treatments/rehabilitation.vue' /* webpackChunkName: "pages/treatments/rehabilitation" */))
const _de7a6304 = () => interopDefault(import('../pages/treatments/shockwave-therapy.vue' /* webpackChunkName: "pages/treatments/shockwave-therapy" */))
const _8660046a = () => interopDefault(import('../pages/treatments/soft-tissue-mobilization-therapy.vue' /* webpackChunkName: "pages/treatments/soft-tissue-mobilization-therapy" */))
const _521260da = () => interopDefault(import('../pages/treatments/therapeutic-massage.vue' /* webpackChunkName: "pages/treatments/therapeutic-massage" */))
const _f8593770 = () => interopDefault(import('../pages/treatments/ultrasound-therapy.vue' /* webpackChunkName: "pages/treatments/ultrasound-therapy" */))
const _3a7f8554 = () => interopDefault(import('../pages/treatments/vestibular-therapy.vue' /* webpackChunkName: "pages/treatments/vestibular-therapy" */))
const _36ca6ed8 = () => interopDefault(import('../pages/treatments/women-health.vue' /* webpackChunkName: "pages/treatments/women-health" */))
const _9ce190f2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c317da68 = () => interopDefault(import('../pages/jobs/_job.vue' /* webpackChunkName: "pages/jobs/_job" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/conditions",
    component: _21909e96,
    name: "conditions"
  }, {
    path: "/contact-us",
    component: _d6adf8ee,
    name: "contact-us"
  }, {
    path: "/jobs",
    component: _5d6b64f4,
    name: "jobs"
  }, {
    path: "/thanks",
    component: _6df4d232,
    name: "thanks"
  }, {
    path: "/treatments",
    component: _05a4c719,
    name: "treatments"
  }, {
    path: "/jobs/jobApplication",
    component: _9619d9be,
    name: "jobs-jobApplication"
  }, {
    path: "/treatments/acupuncture-therapy-in-edmonton",
    component: _0c90a7a5,
    name: "treatments-acupuncture-therapy-in-edmonton"
  }, {
    path: "/treatments/cupping-therapy-in-edmonton",
    component: _10353754,
    name: "treatments-cupping-therapy-in-edmonton"
  }, {
    path: "/treatments/dry-needling-therapy-in-edmonton",
    component: _18555ad8,
    name: "treatments-dry-needling-therapy-in-edmonton"
  }, {
    path: "/treatments/electrotherapeutic-therapy",
    component: _d78ce3b2,
    name: "treatments-electrotherapeutic-therapy"
  }, {
    path: "/treatments/interferential-therapy",
    component: _300588e5,
    name: "treatments-interferential-therapy"
  }, {
    path: "/treatments/joint-manipulative-therapy",
    component: _107f33b5,
    name: "treatments-joint-manipulative-therapy"
  }, {
    path: "/treatments/joint-manual-mobilization-therapy",
    component: _4316fca8,
    name: "treatments-joint-manual-mobilization-therapy"
  }, {
    path: "/treatments/kinesiology-taping-therapy",
    component: _119db338,
    name: "treatments-kinesiology-taping-therapy"
  }, {
    path: "/treatments/laser-therapy",
    component: _7326402e,
    name: "treatments-laser-therapy"
  }, {
    path: "/treatments/rehabilitation",
    component: _f6970c8c,
    name: "treatments-rehabilitation"
  }, {
    path: "/treatments/shockwave-therapy",
    component: _de7a6304,
    name: "treatments-shockwave-therapy"
  }, {
    path: "/treatments/soft-tissue-mobilization-therapy",
    component: _8660046a,
    name: "treatments-soft-tissue-mobilization-therapy"
  }, {
    path: "/treatments/therapeutic-massage",
    component: _521260da,
    name: "treatments-therapeutic-massage"
  }, {
    path: "/treatments/ultrasound-therapy",
    component: _f8593770,
    name: "treatments-ultrasound-therapy"
  }, {
    path: "/treatments/vestibular-therapy",
    component: _3a7f8554,
    name: "treatments-vestibular-therapy"
  }, {
    path: "/treatments/women-health",
    component: _36ca6ed8,
    name: "treatments-women-health"
  }, {
    path: "/",
    component: _9ce190f2,
    name: "index"
  }, {
    path: "/jobs/:job",
    component: _c317da68,
    name: "jobs-job"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
