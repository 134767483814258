export const AppointmentButton = () => import('../../components/buttons/AppointmentButton.vue' /* webpackChunkName: "components/appointment-button" */).then(c => wrapFunctional(c.default || c))
export const CallButton = () => import('../../components/buttons/callButton.vue' /* webpackChunkName: "components/call-button" */).then(c => wrapFunctional(c.default || c))
export const EmailButton = () => import('../../components/buttons/emailButton.vue' /* webpackChunkName: "components/email-button" */).then(c => wrapFunctional(c.default || c))
export const ViewMoreTreatments = () => import('../../components/buttons/view-more-treatments.vue' /* webpackChunkName: "components/view-more-treatments" */).then(c => wrapFunctional(c.default || c))
export const ViewOurServicesButton = () => import('../../components/buttons/view-our-services-button.vue' /* webpackChunkName: "components/view-our-services-button" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/forms/contact-form.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const EmailSubscribeFooter = () => import('../../components/email/email-subscribe-footer.vue' /* webpackChunkName: "components/email-subscribe-footer" */).then(c => wrapFunctional(c.default || c))
export const EmailSubscribe = () => import('../../components/email/email-subscribe.vue' /* webpackChunkName: "components/email-subscribe" */).then(c => wrapFunctional(c.default || c))
export const BookAppointment = () => import('../../components/homepageSections/BookAppointment.vue' /* webpackChunkName: "components/book-appointment" */).then(c => wrapFunctional(c.default || c))
export const AcupunctureSection = () => import('../../components/homepageSections/acupuncture-section.vue' /* webpackChunkName: "components/acupuncture-section" */).then(c => wrapFunctional(c.default || c))
export const ConditionsTreated = () => import('../../components/homepageSections/conditions-treated.vue' /* webpackChunkName: "components/conditions-treated" */).then(c => wrapFunctional(c.default || c))
export const DirectBilling = () => import('../../components/homepageSections/direct-billing.vue' /* webpackChunkName: "components/direct-billing" */).then(c => wrapFunctional(c.default || c))
export const MainSection = () => import('../../components/homepageSections/main-section.vue' /* webpackChunkName: "components/main-section" */).then(c => wrapFunctional(c.default || c))
export const MassageSection = () => import('../../components/homepageSections/massage-section.vue' /* webpackChunkName: "components/massage-section" */).then(c => wrapFunctional(c.default || c))
export const MotorVehicle = () => import('../../components/homepageSections/motorVehicle.vue' /* webpackChunkName: "components/motor-vehicle" */).then(c => wrapFunctional(c.default || c))
export const OurServices = () => import('../../components/homepageSections/our-services.vue' /* webpackChunkName: "components/our-services" */).then(c => wrapFunctional(c.default || c))
export const PhysiotherapySection = () => import('../../components/homepageSections/physiotherapy-section.vue' /* webpackChunkName: "components/physiotherapy-section" */).then(c => wrapFunctional(c.default || c))
export const PrivateRooms = () => import('../../components/homepageSections/private-rooms.vue' /* webpackChunkName: "components/private-rooms" */).then(c => wrapFunctional(c.default || c))
export const TestimonialsSection = () => import('../../components/homepageSections/testimonials-section.vue' /* webpackChunkName: "components/testimonials-section" */).then(c => wrapFunctional(c.default || c))
export const TreatmentMethods = () => import('../../components/homepageSections/treatment-methods.vue' /* webpackChunkName: "components/treatment-methods" */).then(c => wrapFunctional(c.default || c))
export const WcbInformation = () => import('../../components/homepageSections/wcbInformation.vue' /* webpackChunkName: "components/wcb-information" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/navigationBar/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const DesktopNavbar = () => import('../../components/navigationBar/desktop-navbar.vue' /* webpackChunkName: "components/desktop-navbar" */).then(c => wrapFunctional(c.default || c))
export const MobileNavbar = () => import('../../components/navigationBar/mobile-navbar.vue' /* webpackChunkName: "components/mobile-navbar" */).then(c => wrapFunctional(c.default || c))
export const AppointmentLinkConditions = () => import('../../components/links/appointmentLinkConditions.vue' /* webpackChunkName: "components/appointment-link-conditions" */).then(c => wrapFunctional(c.default || c))
export const BookAppointmentLink = () => import('../../components/links/bookAppointmentLink.vue' /* webpackChunkName: "components/book-appointment-link" */).then(c => wrapFunctional(c.default || c))
export const EmailSubscriptionDialog = () => import('../../components/dialogBoxes/emailSubscriptionDialog.vue' /* webpackChunkName: "components/email-subscription-dialog" */).then(c => wrapFunctional(c.default || c))
export const DesktopLogo = () => import('../../components/logo/desktopLogo.vue' /* webpackChunkName: "components/desktop-logo" */).then(c => wrapFunctional(c.default || c))
export const FooterLogo = () => import('../../components/logo/footer-logo.vue' /* webpackChunkName: "components/footer-logo" */).then(c => wrapFunctional(c.default || c))
export const MobileLogo = () => import('../../components/logo/mobile-logo.vue' /* webpackChunkName: "components/mobile-logo" */).then(c => wrapFunctional(c.default || c))
export const BasicInfo = () => import('../../components/footer/basic-info.vue' /* webpackChunkName: "components/basic-info" */).then(c => wrapFunctional(c.default || c))
export const FooterMain = () => import('../../components/footer/footer-main.vue' /* webpackChunkName: "components/footer-main" */).then(c => wrapFunctional(c.default || c))
export const LocationMap = () => import('../../components/footer/location-map.vue' /* webpackChunkName: "components/location-map" */).then(c => wrapFunctional(c.default || c))
export const PrivacyLink = () => import('../../components/footer/privacy-link.vue' /* webpackChunkName: "components/privacy-link" */).then(c => wrapFunctional(c.default || c))
export const TemporaryFooter = () => import('../../components/footer/temporary-footer.vue' /* webpackChunkName: "components/temporary-footer" */).then(c => wrapFunctional(c.default || c))
export const TermsAndConditionsLink = () => import('../../components/footer/terms-and-conditions-link.vue' /* webpackChunkName: "components/terms-and-conditions-link" */).then(c => wrapFunctional(c.default || c))
export const LocationContact = () => import('../../components/location/location-contact.vue' /* webpackChunkName: "components/location-contact" */).then(c => wrapFunctional(c.default || c))
export const MainCta = () => import('../../components/call-to-actions/main-cta.vue' /* webpackChunkName: "components/main-cta" */).then(c => wrapFunctional(c.default || c))
export const RequestAppointmentCta = () => import('../../components/call-to-actions/request-appointment-cta.vue' /* webpackChunkName: "components/request-appointment-cta" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbHeading = () => import('../../components/breadcrumb-heading/breadcrumb-heading.vue' /* webpackChunkName: "components/breadcrumb-heading" */).then(c => wrapFunctional(c.default || c))
export const SidePanel = () => import('../../components/side-panels/side-panel.vue' /* webpackChunkName: "components/side-panel" */).then(c => wrapFunctional(c.default || c))
export const ConditionsToBeWorkedOnAnklePain = () => import('../../components/conditions-to-be-worked-on/ankle-pain.vue' /* webpackChunkName: "components/conditions-to-be-worked-on-ankle-pain" */).then(c => wrapFunctional(c.default || c))
export const ConditionsToBeWorkedOnBackPain = () => import('../../components/conditions-to-be-worked-on/back-pain.vue' /* webpackChunkName: "components/conditions-to-be-worked-on-back-pain" */).then(c => wrapFunctional(c.default || c))
export const ConditionsToBeWorkedOnElbowPain = () => import('../../components/conditions-to-be-worked-on/elbow-pain.vue' /* webpackChunkName: "components/conditions-to-be-worked-on-elbow-pain" */).then(c => wrapFunctional(c.default || c))
export const ConditionsToBeWorkedOnFootPain = () => import('../../components/conditions-to-be-worked-on/foot-pain.vue' /* webpackChunkName: "components/conditions-to-be-worked-on-foot-pain" */).then(c => wrapFunctional(c.default || c))
export const ConditionsToBeWorkedOn = () => import('../../components/conditions-to-be-worked-on/index.vue' /* webpackChunkName: "components/conditions-to-be-worked-on" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
