
export default {
  data() {
    return {
      drawer: false,
      selectedItem: 0,
      items: [
        {
          name: 'Home',
          link_path: '/',
        },
        {
          name: 'What We Treat',
          link_path: '/conditions',
        },
        {
          name: 'How We Treat',
          link_path: '/treatments',
        },
        // {
        //   name: 'About',
        //   link_path: '/about-us',
        // },
        {
          name: 'Contact Us',
          link_path: '/contact-us',
        },
      ],
    }
  },
}
