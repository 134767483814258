export default function ({ $axios, redirect,app }) {
    $axios.onRequest(response => {
      console.log(response)
    })
   
    $axios.onError(error => {
      nuxtError({
        statusCode: error.response.status,
        message: error.message,
      });
      return Promise.resolve(false);
    })


  }