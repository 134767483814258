
export default {
  data() {
    return {
      icons: [
        {
          name: 'mdi-facebook',
        },
        {
          name: 'mdi-instagram',
        },
        {
          name: 'mdi-linkedin',
        },
      ],

      links: [
        {
          heading: 'What We Treat',
          content: [
            {
              name: 'Neck Pain',
              path: '/conditions',
            },
            {
              name: 'Back Pain',
              path: '/conditions',
            },
            {
              name: 'Knee Pain',
              path: '/conditions',
            },
            {
              name: 'More Conditions',
              path: '/conditions',
            },
          ],
        },
        {
          heading: 'How We Treat',
          content: [
            {
              name: 'Shockwave Therapy',
              path: '/treatments/shockwave-therapy',
            },
            {
              name: 'Laser Therapy',
              path: '/treatments/laser-therapy',
            },
            {
              name: 'Ultrasound Therapy',
              path: '/treatments/ultrasound-therapy',
            },
            {
              name: 'More Treatments',
              path: '/treatments',
            },
          ],
        },
        // {
        //   heading: 'About',
        //   content: [
        //     {
        //       name: 'Team',
        //       path: '/our-team',
        //     },
        //     {
        //       name: 'Location',
        //       path: '/location',
        //     },
        //     {
        //       name: 'Direct Billing',
        //       path: '/direct-billing',
        //     },
        //     {
        //       name: 'Careers',
        //       path: '/careers',
        //     },
        //   ],
        // },
        {
          heading: 'Quick Links',
          content: [
            {
              name: 'Home',
              path: '/',
            },
            {
              name: 'Contact',
              path: '/contact-us',
            },
            {
              name: 'Join Our Team',
              path: '/jobs',
            },
            // {
            //   name: 'Book Appointment',
            //   path: 'https://restopainphysio.janeapp.com',
            // },
          ],
        },
      ],
    }
  },
}
