
export default {
  head: {
    // script: [
    //   {
    //     src: ((w, d, s, l, i) => {
    //       w[l] = w[l] || []
    //       w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    //       var f = d.getElementsByTagName(s)[0],
    //         j = d.createElement(s),
    //         dl = l != 'dataLayer' ? '&l=' + l : ''
    //       j.async = true
    //       j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    //       f.parentNode.insertBefore(j, f)
    //     })(window, document, 'script', 'dataLayer', 'GTM-MN6BQ23G'),
    //   },
    //   // {
    //   //   src: 'https://www.googletagmanager.com/gtag/js?id=G-VN52YJ2N5W',
    //   //   async: true,
    //   // },
    //   //     {
    //   //       innerHTML: ` window.dataLayer = window.dataLayer || [];
    //   // function gtag(){dataLayer.push(arguments);}
    //   // gtag('js', new Date());

    //   // gtag('config', 'G-VN52YJ2N5W');`,
    //   //     },
    // ],
  },
}
