import { render, staticRenderFns } from "./contact.vue?vue&type=template&id=16e21e9c&"
import script from "./contact.vue?vue&type=script&lang=js&"
export * from "./contact.vue?vue&type=script&lang=js&"
import style0 from "./contact.vue?vue&type=style&index=0&id=16e21e9c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/home/satinder/Desktop/work/projects/rest-o-pain/main_project/rest-o-pain-main/components/navigationBar/NavBar.vue').default,FooterMain: require('/home/satinder/Desktop/work/projects/rest-o-pain/main_project/rest-o-pain-main/components/footer/footer-main.vue').default})
