import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=43bf4a92&scoped=true&"
import script from "./NavBar.vue?vue&type=script&lang=js&"
export * from "./NavBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43bf4a92",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileNavbar: require('/home/satinder/Desktop/work/projects/rest-o-pain/main_project/rest-o-pain-main/components/navigationBar/mobile-navbar.vue').default,DesktopNavbar: require('/home/satinder/Desktop/work/projects/rest-o-pain/main_project/rest-o-pain-main/components/navigationBar/desktop-navbar.vue').default})
