export const state = () =>({
    jobs: [],
    job_fetch_error: undefined
})
export const getters = {
    getJobs(state){
        return state.jobs
    },
    getJobFetchError(state){
        return state.job_fetch_error
    }
}
export const mutations = {
    setJobs(state,payload){
        state.jobs = payload
    },
    setJobFetchError(state,payload){
        state.job_fetch_error = payload
    }
}
export const actions = {
    async fetchJobs({ commit }) {
        try {
          let response = await this.$axios.get('/api/jobs')
          console.log(response)
          commit('setJobs',response['data'])
        } catch (error) {
          commit('setJobFetchError', error)
        }
      },
}