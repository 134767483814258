
// import Appointment from '../buttons/Appointment.vue'
// import AppointmentButton from '../buttons/appointmentButton.vue'
export default {
  data() {
    return {
      // links: [
      //   {
      //     to: 'Home',
      //     linkPath: '/',
      //   },
      //   {
      //     to: 'Conditions',
      //     linkPath: '/conditions/',
      //   },
      //   {
      //     to: 'Treatments',
      //     linkPath: '/treatments/',
      //   },
      //   {
      //     to: 'Careers',
      //     linkPath: '/jobs/',
      //   },
      //   {
      //     to: 'Contact Us',
      //     linkPath: '/contact-us/',
      //   },
      // ],
    }
  },
}
